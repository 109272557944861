import './PageSectionQuote.scss';

import React from 'react';

import BaseIcon from '../BaseIcon/BaseIcon';
import BaseSeparator from '../BaseSeparator/BaseSeparator';
import { IconName } from '../Symbols';
import { Renderable } from '../../types/base.types';
import joinClassNames from '../../utils/className.utils';

type PageSectionQuoteProps = {
  className?: string,
  icon?: IconName,
  iconSize?: string | number,
  quote: string,
  quoteAuthor: string,
  ArticleParagraphs: React.ReactNode[],
  Logo: Renderable,
}

const PageSectionQuote: React.FC<PageSectionQuoteProps> = props => {
  const p = props;
  return <section className={joinClassNames("page-section PageSectionQuote", p.className)}>
    {p.Logo && <figure className="PageSectionQuote__logo">
      {p.Logo}
    </figure>}
    <div className="container">
      <blockquote>
        <BaseIcon icon={p.icon} size={p.iconSize} />
        <p>{p.quote}</p>
        {p.quoteAuthor && <footer>{p.quoteAuthor}</footer>}
      </blockquote>
      <BaseSeparator />
      <article>
        {p.ArticleParagraphs.map((El, idx) => <React.Fragment key={idx}>
          {El}
        </React.Fragment>)}
      </article>
    </div>
  </section>

}

export default PageSectionQuote;