import React from 'react';
import APP_CONTENT_CONFIG from '../../constants/appContentConfig.constants';
import './BaseSeparator.scss';

const BaseSeparator = () => {
  const { baseSeparator } = APP_CONTENT_CONFIG;
  return (
    <div className="symbol-separator">
      {baseSeparator.Icon}
    </div>
  )
}

export default BaseSeparator;